@import './@include.scss';
@import './custom-variables';

.b-white {
  background: #ffffff;
}

.c-copy {
  color: $color__font-copy
}

.c-light-copy {
  color: $cp-gray-font-sub-copy-light;
}

.c-primary {
  color: $color__font-primary;
}

.c-pointer {
  cursor: pointer;
}

.c-pointer-underline {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.c-white {
  color: white;
}

.capitalize {
  text-transform: capitalize;
}

.card {
  background: white;
  border-radius: $border-radius;
  box-shadow: $bs-1;
  position: relative;

}

.content-bg {
  background: $cp-content-background;
}

.d-block {
  display: block;
}

.d-iblock {
  display: inline-block;
}

.d-none {
  display: none;
}

.fs-1-0-rem {
  font-size: 1rem;
}

.fs-1-1-rem {
  font-size: 1rem;
}

.fs-1-2-rem {
  font-size: 1.2rem;
}

.fs-1-3-rem {
  font-size: 1.3rem;
}

.fs-1-6-rem {
  font-size: 1.6rem;
}

.fs-1-6-rem {
  font-size: 1.6rem;
}

.fs-1-7-rem {
  font-size: 1.7rem;
}

.fs-1-8-rem {
  font-size: 1.8rem;
}

.fs-2-0-rem {
  font-size: 2rem;
}

.fw-light {
  font-weight: $font-weight-light;
}

.fw-normal {
  font-weight: $font-weight-regular;
}

.fw-sbold {
  font-weight: $font-weight-semi-bold;
}

.fw-bold {
  font-weight: $font-weight-bold;
}

.fw-vbold {
  font-weight: $font-weight-very-bold;
}

.fw-black {
  font-weight: $font-weight-black;
}

.fw-600 {
  font-weight: 600;
}

.fw-800 {
  font-weight: 800;
}

.lh-initial {
  line-height: initial;
}

.h100 {
  height: 100%;
}

.w100 {
  width: 100%;
}

.o-x-auto {
  overflow-x: auto;
}

.o-x-hidden {
  overflow-x: hidden;
}

.o-y-auto {
  overflow-y: auto;
}

.o-hidden {
  overflow: hidden;
}

.o-vis{
  overflow: visible;
}

.p-abs {
  position: absolute;
}

.p-rel {
  position: relative;
}

.popover-title-icon {
  margin-right: .5rem;
}

.subtitle {
  color: $cp-gray-font-sub-copy;
  font-size: 12.5px;
  font-weight: 400;
}

.full-abs {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.divider-faint {
  background: rgba(0, 0, 0, 0.03);
  height: 1px;
  margin: 40px 0;
  min-width: 100%;
  width: 100%;
}

.full-fixed {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.margin0 {
  margin: 0;
}

.hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.invisible-nosize {
  height: .16px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: .1px;
  z-index: -1;
}

.ta-left {
  text-align: left;
}

.ta-center {
  text-align: center;
}

.tac {
  text-align: center;
}

.ta-right {
  text-align: right;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ui-segment {
  border: 1px solid rgba(34,36,38,.15);
  border-radius: .3rem;
  margin-bottom: 5rem;
  padding: 3rem;
}

// Custom animations
@keyframes fadeContinuous {
  0%   {opacity:1;}
  50%  {opacity:0;}
  100% {opacity:1;}
}
@-moz-keyframes fadeContinuous {
  0%   {opacity:1;}
  50%  {opacity:0;}
  100% {opacity:1;}
}
@-webkit-keyframes fadeContinuous {
  0%   {opacity:1;}
  50%  {opacity:0;}
  100% {opacity:1;}
}
.fade-continuous {
   -webkit-animation: fadeContinuous 2s infinite;
   -moz-animation: fadeContinuous 2s infinite;
   -o-animation: fadeContinuous 2s infinite;
    animation: fadeContinuous 2s infinite;
    animation-duration: 2.5s !important;
}

.G-code {
  background: #f5f5f5;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  overflow: auto;
  padding: 5px;
  word-break: break-all;
}

.G-option-inactive {
  border: solid 1px rgba(0, 0, 0, 0.2);

  &:hover {
    border: solid 1px rgba(0, 0, 0, 0.6);
  }
}

.G-option-active {
  border: solid 1px $t-dodger-blue;
}

.G-card {
  background: white;
  border: $generic-gray-border;
  border-radius: $border-radius;
  position: relative;
}

.G-card__title,
.G-card__body {
  padding: .8rem;
}

.G-card__title {
  border-bottom: $generic-gray-border;
  font-size: 1.4rem;
  font-weight: bold;
}

.G-c-pointer {
  cursor: pointer;
}

.G-c-default {
  cursor: default;
}

.G-nowrap {
  white-space: nowrap;
}

.G-o-y-vis {
  overflow-y: visible;
}
