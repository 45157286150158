@font-face {
  font-family: mont-black;
  src: url(./fonts/montserrat/Montserrat-Black.ttf) format("truetype");
}

@font-face {
  font-family: mont-black;
  src: url(./fonts/montserrat/Montserrat-Black.ttf) format("truetype");
}

@font-face {
  font-family: nunito;
  font-weight: 300;
  src: url(./fonts/nunito-sans/NunitoSans-Light.ttf) format("truetype");
}

@font-face {
  font-family: nunito;
  font-weight: 400;
  src: url(./fonts/nunito-sans/NunitoSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: nunito;
  font-weight: 600;
  src: url(./fonts/nunito-sans/NunitoSans-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: nunito;
  font-weight: 700;
  src: url(./fonts/nunito-sans/NunitoSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: nunito;
  font-weight: 800;
  src: url(./fonts/nunito-sans/NunitoSans-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: nunito;
  font-weight: 900;
  src: url(./fonts/nunito-sans/NunitoSans-Black.ttf) format("truetype");
}

