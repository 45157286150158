// Theme
// https://coolors.co/5e02f4-00c49a-dc136c-b892ff-2191fb-eef0f2-f75c03-0d160b-3c3c3c-5e574d
$t-han-purple: #5e02f4ff;
$t-caribbean-green: #00c49aff;
$t-dogwood-rose: #dc136cff;
$t-lavender-floral: #b892ffff;
// This is primary
$t-dodger-blue: #2191fbff;
$t-cultured: #eef0f2ff;
$t-orange-pantone: #f75c03ff;
$t-orange-pantone-light: #fa9d67;
$t-dark-jungle-green: #0D160B;
// This is text-color.
$t-jet: #3c3c3cff;
$t-jet-light-1: #767b76;
// $t-jet-light-2: #aaada9;
$t-jet-light-2: lighten($t-jet, 40%);
$t-davys-grey: #5e574dff;

// Z-index!
$z-index: (
  base            : 1,
  loader          : 10,
  modal-shadow    : 4999,
  modal           : 5000,
  toast           : 6000,
  top             : 10000,
);

// @include media. Note these values align with Zorro breakpoints.
$breakpoints: (
  xs: 480px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
) !default;

// Font family
$font-stack-sans: nunito, 'Open Sans', 'Helvetica Neue', Arial , Helvetica, sans-serif;

// Font family
$font-stack-montserrat: 'Montserrat', nunito, 'Open Sans', 'Helvetica Neue', Arial , Helvetica, sans-serif;

// Font weights
$font-weight-very-light: 300;
$font-weight-light: 400;
$font-weight-regular: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-very-bold: 800;
$font-weight-black: 900;

// Colors
$background-color__gray: #e3e8ee;
$color__action-blue: #5077FB;
$color__font-primary: #313D4D;
$color__font-copy: #6F7680;

// Social
$google: #dd4b39;
$facebook: #3b5998;
$font-color-input-label: #4a4a4a;
$linkedin: #007bb6;
$twitter: #00aced;

// Box-shadows
$bs-1: 0 1px 1px rgba(0, 0, 0, 0.2), 0 0px 1px rgba(0, 0, 0, 0.2);

// Borders
$generic-gray-border-color: #e8e8e8;
$generic-gray-border: solid 1px $generic-gray-border-color;
$faint-gray-border-1: solid 1px rgba(0, 0, 0, 0.04);

// // Color Pallette
$cp-blurple: #5e02f4ff;
$cp-blurple-light: #7e34f6;
$cp-content-background: #fafafa;
$cp-gray-background: rgb(245, 247, 249);
$cp-gray-border: rgb(232, 232, 232);
$cp-gray-border-hover: #778899;
$cp-gray-subtitle: rgba(0, 0, 0, 0.45);
$cp-lime: #b3ff00;
$cp-lime-dark: #8fcc00;
$cp-hot: $t-orange-pantone;
$cp-hot-light: #ff8e52;
$cp-pink: #ff00a0;
$cp-pink-dark: #cc0080;
$cp-purple: #ba0bff;
$cp-purple-light: #c73bff;
$cp-yellow: #f2ff00;
$cp-yellow-dark: #d9e500;
$zorro-green: #52c41a;
$zorro-info: #1890ff;
$zorro-red: #f5222d;
$zorro-warn: #faad14;

// Main font-colors
$cp-black-font: #242A31;
$cp-gray-font-copy: #5c6975;
$cp-gray-font-sub-copy: #aa9db6;
$cp-gray-font-sub-copy-light: #cacdd3;

// Sizes
$border-radius: 4px;

// // Transitions times
// $transition-fast: 0.5s;
// $transition-normal: 1s;
// $transition-slow: 1.5s;

// Misc
$block-header-border: solid 1px rgba(34, 36, 38, 0.15);



