// Shared styles in main modules (e.g. home, organization, account, etc).
.main-modules-container {
  max-width: 120rem;
  min-width: 40rem;
  padding: 2rem 4rem;
  width: 100%;
  overflow: scroll;
}

.main-modules-header {
  font-size: 2rem;
  font-weight: 800;
  padding: 1rem 0;
}
