// The UI theme set.
@import '../../../../node_modules/ng-zorro-antd/ng-zorro-antd.less';

// Unfortunately, we need to replicate our site theme from variables.scss to here (for now).
// $t-dodger-blue: #2191fbff;
@primary-color    :   #07F337;
// $jet: #3c3c3cff;
@text-color       :   #3c3c3cff;

.ant-select-dropdown {
  background-color: #E5E5E5 !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: unset !important;
}

.ant-alert-info {
  background-color: #E5E5E5 !important;
  border-color: #aaa !important;
}

.ant-input-number-lg input {
  text-align: center;
}



.ant-modal-body .ant-btn.ant-btn-primary {
  background: #07F337;
  color: #2d2d2d !important;
}

.ant-modal-body .ant-btn.ant-btn-primary:hover {
  background: #2d2d2d !important;
  border-color: #2d2d2d !important;
  color: #07F337 !important;
}

.ant-modal-body .ant-btn.ant-btn-primary {
  color: #2d2d2d !important;
}

.ant-modal-body .ant-btn:not(.ant-btn-primary) {
  color: #2d2d2d !important;
  background: transparent !important;
}

.ant-modal-body .ant-btn:not(.ant-btn-primary):hover {
  color: #2d2d2d !important;
  background: #e5e5e5 !important;
  border-color: #e5e5e5 !important;
}

.ant-modal-body .ant-btn:not(.ant-btn-primary):focus {
  border-color: #e5e5e5 !important;
  color: #2d2d2d !important;
}




// Don't want sorted column headers to change colors. Looks weird. The below color is the original color of the header before sort.
.ant-table-thead > tr > th.ant-table-column-sort {
  background: rgb(250, 250, 250);
}

h1,h2,h3,h4,h5,h5 {
  margin: 0;
}

.ant-modal-body {
  position: relative;
}

// Remove the padding from the collapse feature.
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.ant-menu-submenu-title {
  cursor: default;
}

// Default line-height is a little too big.
.ant-form-item-label {
  // line-height: 1.5;
}

// Zorro labels do some weird shit if they are too long.
.ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  text-align: left;
  white-space: normal;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0;
}

// Get rid of colon.
.ant-form-item-label > label::after {
  content: unset;
}

// Put the required asterisk '*' after the label, not before.
.ant-form-item-required::before {
  position: absolute;
  right: -11px;
}

// Some kind of weird calc(100% + 1px) is the default for the menu..dunno why.
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}

.ant-table-filter-trigger {
  font-size: 1.6rem;
}
