.event-submenu-no-arrow {
  .ant-menu-submenu-arrow {
    display: none;
  }
}

.create-room-drawer {
  .ant-drawer-wrapper-body {
    display: flex;
    height: 100%;
    flex-flow: column;
  }

  .ant-drawer-header {
    flex-shrink: 1;
  }

  .ant-drawer-body {
    flex-grow: 1;
    height: 100%;
    overflow: auto;
  }
}

.event-chat-ctrl-sub-menu {
  .ant-menu-submenu-title {
    color: $t-jet !important;
    cursor: pointer;
    font-size: 1.5rem !important;
    font-weight: 700;
    height: 3rem !important;
    line-height: 3rem !important;
    text-transform: uppercase;

    &:hover {
      color: $t-dodger-blue !important;
    }
  }
}

.event-toolbar-user-popover-menu-content {
  .ant-popover-inner-content {
    padding: 0;
  }
}

