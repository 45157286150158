.G-text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.J-btn--text-on-gray, .J-btn--primary, .G-btn--rose, .G-btn--green, .G-btn--hot {
  transition: all linear 0.1s;
}

.block {
  background-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 0px 1px rgba(0, 0, 0, 0.2);
}

.block--bottom {
  margin-bottom: 4rem;
}

.block__header {
  align-items: center;
  border-bottom: solid 1px rgba(34, 36, 38, 0.15);
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 1.4rem;
  position: relative;
}

.block__content {
  padding: 3rem;
}

.block__header-loader {
  margin-left: 1rem;
}

.block__title-count {
  margin-left: 2rem;
}

.message-tpl .message-tpl__icon {
  margin-right: 2rem;
  min-width: 3rem;
  width: 3rem;
}
.message-tpl .message-tpl__icon i {
  color: #108ee9;
  font-size: 2.4rem;
}
.message-tpl .message-tpl__header {
  font-size: 16px;
  margin-bottom: 1rem;
}
.message-tpl .message-tpl__body {
  font-size: 14px;
}

.G-btn--hot {
  background-color: #f75c03ff !important;
  border: none !important;
  color: white !important;
}
.G-btn--hot:hover {
  background-color: #fc6b17 !important;
}

.G-btn--green {
  background-color: #00c49aff !important;
  border: none !important;
  color: white !important;
}
.G-btn--green:hover {
  background-color: #00deae !important;
}

.G-btn--rose {
  background-color: #dc136cff !important;
  border: none !important;
  color: white !important;
}
.G-btn--rose:hover {
  background-color: #eb1d78 !important;
}

.G-badge {
  background-color: #f75c03ff;
  border-radius: 4px;
  color: white;
  font-size: 1.2rem;
  font-weight: 700;
  height: 16px;
  line-height: 16px;
  padding: 0 4px;
  text-align: center;
}

.G-m-auto {
  margin: auto;
}

.G-danger-color {
  color: #f5222d !important;
}

.G-happy-color {
  color: #52c41a !important;
}

.G-info-color {
  color: #1890ff !important;
}

.G-warn-color {
  color: #faad14 !important;
}

.G-btn-danger-default:hover, .G-btn-danger-default:focus {
  border-color: #f5222d !important;
  color: #f5222d !important;
}

.J-btn--ghost {
  border-color: #524F50 !important;
  color: #07F337 !important;
}
.J-btn--ghost:hover {
  border-color: #4dfa70 !important;
  color: #4dfa70 !important;
}

.J-btn--primary {
  background-color: #07F337 !important;
  border: solid 1px transparent !important;
  color: #070707 !important;
  font-size: 16px !important;
}
.J-btn--primary:hover {
  background-color: transparent !important;
  color: #07F337 !important;
  border: solid 1px #07F337 !important;
}

.J-btn--text-on-gray {
  color: #07F337 !important;
  font-size: 16px !important;
}
.J-btn--text-on-gray:hover {
  color: #4dfa70 !important;
}

.J-link {
  color: #07F337;
  font-size: 16px !important;
}
.J-link:hover {
  text-decoration: underline !important;
}

.J-link-white {
  color: #2d2d2d !important;
  font-weight: bold;
}
.J-link-white:hover {
  text-decoration: underline;
}

.J-link-color {
  color: #07F337;
  font-size: 16px !important;
}

.J-link-underline {
  color: #07F337;
  font-size: 16px !important;
}
.J-link-underline:hover {
  color: #4dfa70;
  text-decoration: underline;
}

.main-modules-container {
  max-width: 120rem;
  min-width: 40rem;
  padding: 2rem 4rem;
  width: 100%;
  overflow: scroll;
}

.main-modules-header {
  font-size: 2rem;
  font-weight: 800;
  padding: 1rem 0;
}

.popover-override .ant-popover-title {
  background: #f75c03ff;
  color: white;
}

.inverted-tooltip .ant-tooltip-inner {
  background: white;
  color: black;
}
.inverted-tooltip .ant-tooltip-arrow::before {
  background: white;
}

.tooltip-max-size {
  max-height: 50rem;
  overflow-y: auto;
}

.e-manage-modal .ant-modal {
  max-width: 72rem;
  min-width: max-content;
}

.ant-menu-submenu-title {
  transition: color 0s !important;
  color: inherit !important;
}

.ant-menu-item:hover {
  transition: color 0s !important;
}

.danger-panel-collapse .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0;
  padding-left: 0;
}
.danger-panel-collapse .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  left: -1.6rem;
}

.ant-calendar-picker {
  width: 100%;
}

.NZGO-block-upload-button {
  width: 100%;
}
.NZGO-block-upload-button button {
  width: 100%;
}
.NZGO-block-upload-button .ant-upload.ant-upload-select {
  width: 100%;
}
.NZGO-block-upload-button nz-upload-list {
  text-align: center;
  width: 100%;
}

.ant-popover-arrow {
  display: none !important;
}

.ant-message {
  font-size: 1.8rem !important;
}

.ant-message-notice-content {
  box-shadow: 0px 11px 25px 2px rgba(0, 0, 0, 0.31) !important;
}

.ant-tabs-bar {
  margin: 0 !important;
}

.ant-tooltip-inner {
  background-color: #3c3c3cff !important;
}

.ant-btn-circle.ant-btn-sm, .ant-btn-circle-outline.ant-btn-sm {
  line-height: normal;
}

.event-submenu-no-arrow .ant-menu-submenu-arrow {
  display: none;
}

.create-room-drawer .ant-drawer-wrapper-body {
  display: flex;
  height: 100%;
  flex-flow: column;
}
.create-room-drawer .ant-drawer-header {
  flex-shrink: 1;
}
.create-room-drawer .ant-drawer-body {
  flex-grow: 1;
  height: 100%;
  overflow: auto;
}

.event-chat-ctrl-sub-menu .ant-menu-submenu-title {
  color: #3c3c3cff !important;
  cursor: pointer;
  font-size: 1.5rem !important;
  font-weight: 700;
  height: 3rem !important;
  line-height: 3rem !important;
  text-transform: uppercase;
}
.event-chat-ctrl-sub-menu .ant-menu-submenu-title:hover {
  color: #2191fbff !important;
}

.event-toolbar-user-popover-menu-content .ant-popover-inner-content {
  padding: 0;
}