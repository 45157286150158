@import 'custom-variables';

// Zorro stuff.
.popover-override {
  .ant-popover-title {
    background: $cp_hot;
    color: white;
  }
}

.inverted-tooltip {
  .ant-tooltip-inner {
    background: white;
    color: black;
  }

  .ant-tooltip-arrow::before {
    background: white;
  }
}

.tooltip-max-size {
  max-height: 50rem;
  overflow-y: auto;
}

.e-manage-modal {
  .ant-modal {
    max-width: 72rem;
    min-width: max-content;
  }
}

// Override menus to have color we want
.ant-menu-submenu-title {
  transition: color 0s !important;
  color: inherit !important;
}

.ant-menu-item:hover {
  transition: color 0s !important;
}

// Override zorro collapse styles for danger-panel collapse implementation.
.danger-panel-collapse {
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
    padding-left: 0;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    left: -1.6rem;
  }
}

.ant-calendar-picker {
  width: 100%;
}

// big upload button
.NZGO-block-upload-button {
  width: 100%;

  button {
    width: 100%;
  }

  .ant-upload.ant-upload-select {
    width: 100%
  }

  nz-upload-list {
    text-align: center;
    width: 100%;
  }
}

.ant-popover-arrow {
  display: none !important;
}

.ant-message {
  font-size: 1.8rem !important;
}

.ant-message-notice-content {
  box-shadow: 0px 11px 25px 2px rgba(0,0,0,0.31) !important;
}

.ant-tabs-bar {
  margin: 0 !important;
}

.ant-tooltip-inner {
  // This is the $t-jet variable.
  background-color: $t-jet !important;
}

.ant-btn-circle.ant-btn-sm, .ant-btn-circle-outline.ant-btn-sm {
  line-height: normal;
}
