@import "./custom-variables.scss";
%antialias {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%align-items-center {
  align-items: center;
}

%display-flex-align-items-center {
  @extend %align-items-center;
  display: flex;
}

%display-flex-justify-content-center {
  align-items: flex-start;
  align-content: flex-start;
  display: flex;
  justify-content: center;
}

%display-flex-center-both {
  align-items: center;
  display: flex;
  justify-content: center;
}

%full-absolute {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

%full-fixed {
  bottom: 0;
  left: 0;
  position: fixed !important;
  right: 0;
  top: 0;
}

%modal-root {
  @extend %display-flex-center-both;
  @extend %full-fixed;
  z-index: map-get($z-index, modal);
}

%subtitle {
  color: $cp-gray-font-sub-copy;
  font-size: 12.5px;
  font-weight: 400;
}


%text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

%transition-all {
  transition: all linear .1s;
}
