@import 'custom-variables.scss';
@import 'custom-extensions.scss';
// Common app styles.
.block {
  background-color: white;
  box-shadow: $bs-1;
}

.block--bottom {
  margin-bottom: 4rem;
}

.block__header {
  align-items: center;
  border-bottom: solid 1px rgba(34,36,38,.15);
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 1.4rem;
  position: relative;
}

.block__content {
  padding: 3rem;
}

.block__header-actions {
  //
}

.block__header-loader {
  margin-left: 1rem;
}

.block__title-count {
  margin-left: 2rem;
}

.message-tpl {

  .message-tpl__icon {
    margin-right:2rem;
    min-width:3rem;
    width:3rem;

    i {
      color: rgb(16, 142, 233);
      font-size: 2.4rem
    }
  }

  .message-tpl__header {
    font-size:16px;
    margin-bottom: 1rem;
  }

  .message-tpl__body {
    font-size: 14px;
  }
}

.G-btn--hot {
  @extend %transition-all;
  background-color: $t-orange-pantone !important;
  border: none !important;
  color: white !important;

  &:hover {
    background-color: lighten($t-orange-pantone, 5%) !important;
  }
}

.G-btn--green {
  @extend %transition-all;
  background-color: $t-caribbean-green !important;
  border: none !important;
  color: white !important;

  &:hover {
    background-color: lighten($t-caribbean-green, 5%) !important;
  }
}

.G-btn--rose {
  @extend %transition-all;
  background-color: $t-dogwood-rose !important;
  border: none !important;
  color: white !important;

  &:hover {
    background-color: lighten($t-dogwood-rose, 5%) !important;
  }
}

.G-badge {
  background-color: $t-orange-pantone;
  border-radius: $border-radius;
  color: white;
  font-size: 1.2rem;
  font-weight: 700;
  height: 16px;
  line-height: 16px;
  padding: 0 4px;
  text-align: center;
  // width: 16px;
}

.G-m-auto {
  margin: auto;
}

.G-danger-color { color: $zorro-red !important; }
.G-happy-color { color: $zorro-green  !important; }
.G-info-color { color: $zorro-info  !important; }
.G-warn-color { color: $zorro-warn  !important; }

.G-btn-danger-default {
  &:hover, &:focus {
    border-color: $zorro-red !important;
    color: $zorro-red !important;
  }
}

.G-text-ellipsis {
  @extend %text-overflow;
}

// Jessup Branding
$j-green: #07F337;
$j-green-hover: lighten($j-green, 15%);
$j-light-green: #07F337;
$j-light-green-hover: lighten($j-light-green, 15%);
$j-light-green-hover-hex: #57e1cb;
$j-border-color: #524F50;
$j-border: solid 1px $j-border-color;
$j-header-footer: #070707;
$j-room-status-green: #07F337;
$j-room-status-coral: #F06D88;
$j-room-status-blue: #2AD3FF;
$j-gray-bg: #333542;
$j-lt-blue-gray: #C4C5D8;
$j-off-white: #EFEFEF;

.J-btn--ghost {
  border-color: $j-border-color !important;
  color: $j-light-green !important;

  &:hover {
    border-color: $j-green-hover !important;
    color: $j-green-hover !important;
  }
}

.J-btn--primary {
  @extend %transition-all;
  background-color: $j-green !important;
  border: solid 1px transparent !important;
  color: #070707 !important;
  font-size: 16px !important;

  &:hover {
    background-color: transparent !important;
    color: $j-green !important;
    border: solid 1px $j-green !important;
  }
}

.J-btn--text-on-gray {
  @extend %transition-all;
  color: #07F337 !important;
  font-size: 16px !important;

  &:hover {
    color: lighten(#07F337, 15%) !important;
  }
}

.J-link {
  color: $j-light-green;
  font-size: 16px !important;

  &:hover {
    text-decoration: underline !important;
  }
}

.J-link-white {
  color: #2d2d2d !important;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
}

.J-link-color {
  color: $j-light-green;
  font-size: 16px !important;
}

.J-link-underline {
  color: $j-light-green;
  font-size: 16px !important;

  &:hover {
    color: $j-light-green-hover;
    text-decoration: underline
  }
}

